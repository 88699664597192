import { Trans } from '@mbarzda/solid-i18next'
import { CheckoutEventNames } from '@paddle/paddle-js'
import { useLocation, useNavigate, useSearchParams } from '@solidjs/router'
import { t } from 'i18next'
import { type Component, createSignal, onMount, Show } from 'solid-js'
import logoAnimation from '../../assets/animations/logo-animated.json'
import MiniArrowRight from '../../assets/heroicons/MiniArrowRight'
import MiniClock from '../../assets/heroicons/MiniClock'
import { MainModule } from '../../features/main-module'
import { type MainPlans, type MainPlansItem, type OfferingProduct } from '../../features/payment/domain/models/offering'
import { OneTimePurchaseType, type PurchaseProduct } from '../../features/payment/domain/models/one-time-purchase'
import { isSubscription, type Subscription, SubscriptionStatus } from '../../features/payment/domain/models/subscription'
import { type SubscriptionChange } from '../../features/payment/domain/models/subscription-change'
import { useUser } from '../../public/auth/user-provider'
import { AppRoutes } from '../../shared/app-routes'
import { usePaddle } from '../../shared/providers/paddle.provider'
import { useTracking } from '../../shared/providers/tracking.provider'
import LottieAnimation from '../shared/components/LottieAnimation'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import CannotChangePlanDialog from './CannotChangePlanDialog'
import OptOutPeriodDialog from './OptOutPeriodDialog'
import PastDueBlockDialog from './PastDueBlockDialog'
import PlanChangeDialog from './PlanChangeDialog'
import PlanChangeErrorDialog from './PlanChangeErrorDialog'
import ProOfferingBox from './ProOfferingBox'
import ProcessingPlanChangeDialog from './ProcessingPlanChangeDialog'
import StarterOfferingBox from './StarterOfferingBox'
import { OfferingPeriod } from './offering-period'
import type { Interval } from '../../features/payment/data/entities/offering.entity'

export interface OfferingBoxProps {
  readonly offering: MainPlansItem
  readonly period: OfferingPeriod
  readonly fakePrices: Record<OfferingPeriod, number>
  readonly activeProduct: OfferingProduct | PurchaseProduct | undefined
  readonly showCheckout: (productId: string) => Promise<void>
}

const FakePrices = {
  starter: {
    [OfferingPeriod.Month]: 29.99,
    [OfferingPeriod.Year]: 17.99
  },
  pro: {
    [OfferingPeriod.Month]: 69.99,
    [OfferingPeriod.Year]: 41.99
  }
}

const PaymentScreen: Component = () => {
  const [offering, setOffering] = createSignal<MainPlans>()
  const [period, setPeriod] = createSignal<OfferingPeriod>(OfferingPeriod.Year)
  const [isCheckoutCompleted, setIsCheckoutCompleted] = createSignal(false)
  const [isOptOutOpen, setIsOptOutOpen] = createSignal(false)
  const [planChange, setPlanChange] = createSignal<OfferingProduct | undefined>()
  const [isChangingPlan, setIsChangingPlan] = createSignal(false)
  const [changePreview, setChangePreview] = createSignal<SubscriptionChange>()
  const [changePlanError, setChangePlanError] = createSignal(false)
  const [cannotChangePlan, setCannotChangePlan] = createSignal(false)

  const { currentUser, setCurrentUser, userPurchases, setUserPurchases, currentSubscription } = useUser()
  const { paddle } = usePaddle()
  const { trackEvent } = useTracking()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const getCurrentUser = MainModule.getAuthComponent().provideGetCurrentUser()
  const updateCheckoutStatus = MainModule.getPaymentComponent().provideUpdateCheckoutStatus()
  const getMainPlans = MainModule.getPaymentComponent().provideGetMainPlans()
  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()
  const updateSubscription = MainModule.getPaymentComponent().provideUpdateSubscription()
  const previewSubscriptionChange = MainModule.getPaymentComponent().providePreviewSubscriptionChange()

  const NoTrialPlanId = 'early_access_0'
  onMount(() => {
    void getPurchases.execute().then(purchases => {
      setUserPurchases(purchases)

      if (currentSubscription()) {
        void getMainPlans.execute(NoTrialPlanId).then(setOffering)
      } else {
        void getMainPlans.execute(searchParams.offer).then(setOffering)
      }
    })
  })

  const getDiscount = (): number | undefined => {
    const prices = offering()
    if (!prices) return

    return 100 - prices.pro.annual.price / (prices.pro.monthly.price * 12) * 100
  }

  const CheckoutNotFinishedStatuses: CheckoutEventNames[] = [
    CheckoutEventNames.CHECKOUT_CLOSED, CheckoutEventNames.CHECKOUT_ERROR, CheckoutEventNames.CHECKOUT_FAILED
  ]

  const onActionPreview = async (productId: string): Promise<void> => {
    if (currentSubscription()?.status === SubscriptionStatus.Canceled) {
      setCannotChangePlan(true)
      return
    }

    const sub = currentSubscription()
    if (sub && isSubscription(sub)) {
      setPlanChange(getProductById(productId))
      const preview = await previewSubscriptionChange.execute(sub.id, productId)
      setChangePreview(preview)
    } else {
      await showCheckout(productId)
    }
  }

  const showCheckout = async (productId: string): Promise<void> => {
    const user = currentUser()
    if (!user) return

    paddle()?.Update({
      eventCallback: async (data) => {
        if (data.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
          setIsCheckoutCompleted(true)
          // @ts-expect-error fpr is added by first promoter in the head of the index.html
          fpr('referral', { email: user.email, uid: user.id })

          setTimeout(async () => {
            trackEvent(
              'Subscription Checkout',
              { completed: true, productId }
            )
            paddle()?.Checkout.close()
            navigate(AppRoutes.LoadingAccount(), { replace: true })
          }, 1000)
        } else {
          if (data.name) {
            trackEvent(
              'Subscription Checkout',
              { completed: false, reason: data.name }
            )

            if (!isCheckoutCompleted() && CheckoutNotFinishedStatuses.includes(data.name)) {
              await updateCheckoutStatus.execute('abandoned')
            }
          }
        }
      }
    })

    paddle()?.Checkout.open({
      customer: {
        id: user.customerId
      },
      items: [{ priceId: productId, quantity: 1 }],
      customData: {
        email: user.email,
        fp_tid: window.FPROM?.data.tid ?? ''
      }
    })
  }

  const refreshUser = async (route?: string): Promise<void> => {
    // force refresh of the subscription
    const purchases = await getPurchases.execute()
    setUserPurchases(purchases)
    const user = await getCurrentUser.execute()
    setCurrentUser(user)
    paddle()?.Checkout.close()
    if (route) {
      navigate(route, { replace: true })
    }
  }

  const switchPlanPeriod = (): void => {
    period() === OfferingPeriod.Year
      ? setIsOptOutOpen(true)
      : setPeriod(OfferingPeriod.Year)
  }

  const setToMonthlySubscription = (hasAccepted: boolean): void => {
    if (!hasAccepted) {
      setPeriod(OfferingPeriod.Month)
    }
    setIsOptOutOpen(false)
  }

  const getProductById = (productId: string): OfferingProduct | undefined => {
    const offer = offering()
    if (!offer) return

    switch (productId) {
      case offer.starter.monthly.id:
        return offer.starter.monthly
      case offer.starter.annual.id:
        return offer.starter.annual
      case offer.pro.monthly.id:
        return offer.pro.monthly
      case offer.pro.annual.id:
        return offer.pro.annual
    }
  }

  const onActionPlanChange = async (changed: boolean): Promise<void> => {
    const purchasedPlanId = planChange()!.id

    setPlanChange(undefined)
    setChangePreview(undefined)

    if (!changed) {
      return
    }

    setIsChangingPlan(true)
    executeWithUrlScope(
      async () => await updateSubscription.execute(currentSubscription()!.id, purchasedPlanId), location
    )
      .then(() => {
        void refreshUser(AppRoutes.AccountSettings())
      })
      .catch((error) => {
        console.error(error)
        setChangePlanError(true)
      })
      .finally(() => {
        setIsChangingPlan(false)
      })
  }

  const isUserLifetime = (): boolean => currentSubscription()?.productPrice.offeringType === OneTimePurchaseType.Lifetime && !!userPurchases()?.isLifetime

  const getProTrialPeriod = (): Interval | undefined => {
    const offer = offering()
    if (!offer) return

    return period() === OfferingPeriod.Month ? offer.pro.monthly.trialPeriod : offer.pro.annual.trialPeriod
  }
  return (
    <>
      <Show when={currentSubscription()?.status !== SubscriptionStatus.PastDue} fallback={
        <PastDueBlockDialog updatePaymentUrl={(currentSubscription() as Subscription).manageUrl!} />
      }>
        <Show when={cannotChangePlan()}>
          <CannotChangePlanDialog close={() => setCannotChangePlan(false)} subscriptionReactivated={refreshUser} />
        </Show>
        <Show when={isChangingPlan()}>
          <ProcessingPlanChangeDialog />
        </Show>
        <Show when={changePlanError() && isSubscription(currentSubscription())}>{(sub) => (
          <PlanChangeErrorDialog onActionClose={() => setChangePlanError(false)} manageSubscriptionURL={sub().manageUrl!}/>
        )}</Show>
        <Show when={planChange()}>{(product) => (
          <PlanChangeDialog
            onActionAccept={onActionPlanChange}
            fromProduct={(currentSubscription()! as Subscription).productPrice}
            toProduct={product()}
            preview={changePreview()}
          />
        )}</Show>
        <Show when={isOptOutOpen() && offering()}>{(offer) => (
          <OptOutPeriodDialog onActionAccept={setToMonthlySubscription} offering={offer()}/>
        )}</Show>
        <div class="h-full w-full px-6 flex flex-col justify-center items-center gap-6 relative">
          <Show when={offering()}>{(offering) => (
            <>
              <span class="text-4xl font-bold">Choose Your Plan</span>
              <div class="flex gap-3 items-center font-semibold justify-center flex-wrap mb-2">
                <span>Monthly</span>
                <button
                  onClick={switchPlanPeriod}
                  type="button"
                  class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer !bg-[#2BF570] rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                  classList={{
                    '!bg-gray-400': period() === OfferingPeriod.Month,
                    '!bg-[#2BF570]': period() === OfferingPeriod.Year
                  }}
                  role="switch" aria-checked="false">
                  <span aria-hidden="true"
                        class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        classList={{
                          'translate-x-0': period() === OfferingPeriod.Month,
                          'translate-x-5': period() === OfferingPeriod.Year
                        }} />
                </button>
                <div class="flex gap-1 items-center">
                  <span>Annual</span>
                  <span class="text-gray-500"><MiniArrowRight/></span>
                  <div class="border rounded-md h-6 text-sm bg-[#059669] text-green-50 flex items-center w-fit px-2">
                    <span>Save {getDiscount()?.toFixed(0)}%</span>
                  </div>
                </div>
              </div>
              <div class="px-4 rounded-md h-9 highlight-gradient text-white flex gap-2 text-sm items-center">
                <MiniClock /><Trans key="ls_pay_hurry"/>
              </div>
              <div class="flex flex-col-reverse sm:flex-row sm:flex-wrap items-center relative w-full justify-center gap-8 overflow-hidden pb-20">
                <Show when={!isUserLifetime()}>
                  <div class="z-20 h-fit lg:h-full min-w-[350px]">
                    <StarterOfferingBox offering={offering().starter} period={period()}
                                        fakePrices={FakePrices.starter}
                                        activeProduct={currentSubscription() ? (currentSubscription()! as Subscription).productPrice : undefined}
                                        showCheckout={onActionPreview}/>
                  </div>
                </Show>
                <div class="z-20 h-fit lg:h-full min-w-[350px]">
                  <ProOfferingBox offering={offering().pro} period={period()}
                                  fakePrices={FakePrices.pro} activeProduct={currentSubscription()?.productPrice}
                                  showCheckout={onActionPreview} standalone={isUserLifetime()}/>
                </div>
                <div class="hidden lg:block absolute h-[420px] w-full bg-blue_gray-200 top-[68px] rounded-2xl max-w-[1240px]" />
              </div>
              <div class="flex flex-col gap-2 text-center -mt-16 max-w-[360px] sm:max-w-none">
                <Show when={!currentSubscription()}>
                  <div class="flex flex-col text-center">
                    <Show when={getProTrialPeriod()}>
                      <span class="text-sm px-8 sm:px-12 lg:px-0" innerHTML={t('ls_subscription_disclaimer1')}/>
                      <span class="text-sm px-8 sm:px-12 lg:px-0" innerHTML={t('ls_subscription_disclaimer1_2')}/>
                    </Show>
                  </div>
                </Show>
                <span class="text-sm px-8 sm:px-12 lg:px-0" innerHTML={t('ls_subscription_disclaimer2')} />
              </div>
            </>
          )}</Show>
          <Show when={!offering()}>
            <div class="hidden absolute lg:flex h-[420px] w-[calc(100%-48px)] bg-blue_gray-200 mt-[252px] mb-[154px] rounded-2xl max-w-[1240px] justify-center items-center">
              <LottieAnimation animationData={logoAnimation} width="32px"/>
            </div>
            <div class="flex sm:hidden justify-center items-center">
              <LottieAnimation animationData={logoAnimation} width="32px"/>
            </div>
          </Show>
        </div>
      </Show>
    </>
  )
}

export default PaymentScreen
