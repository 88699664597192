import { type Component, createContext, type JSX, useContext } from 'solid-js'
import { MixpanelService, type TrackingService } from '../services/tracking-service'

const TrackingContext = createContext<TrackingService | undefined>(undefined)

export interface TrackingProviderProps {
  readonly children: JSX.Element
}

export const TrackingProvider: Component<TrackingProviderProps> = (props) => {
  const service: TrackingService = new MixpanelService()

  return (
    <TrackingContext.Provider value={ service }>
      {props.children}
    </TrackingContext.Provider>
  )
}

export function useTracking(): TrackingService {
  const context = useContext(TrackingContext)
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider')
  }
  return context
}
