import axios from 'axios'
import { formatMinutesToTimezone } from '../../shared/helpers/date.functions'
import { auth0Client, auth0Config } from './auth-provider'
import { CookieManager, CookieName } from '../../shared/services/cookie-service'

axios.interceptors.request.use(async (config) => {
  try {
    const token = await auth0Client.getTokenSilently(auth0Config())

    if (!!token) {
      CookieManager.set(CookieName.AuthToken, token)
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${token}`
    }

    config.headers['X-Time-Zone'] = formatMinutesToTimezone(new Date().getTimezoneOffset())
  } catch {
    await auth0Client.loginWithRedirect(auth0Config())
  }
  return config
})

export default axios
