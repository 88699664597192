import { TransProvider } from '@mbarzda/solid-i18next'
import { initializePaddle } from '@paddle/paddle-js'
import * as Sentry from '@sentry/browser'
import { Navigate, Outlet, Route, Routes, useLocation } from '@solidjs/router'
import i18next from 'i18next'
import { DEV, Show, createEffect, createSignal, onMount, type Component } from 'solid-js'
import './App.scss'
import translations from './assets/locales/translations.json'
import { MainModule } from './features/main-module'
import MainLayout from './private/MainLayout'
import SimpleLayout from './private/SimpleLayout'
import AccountSettings from './private/account-settings/AccountSettings'
import Catalog from './private/catalog/Catalog'
import CogsIssues from './private/cogs-issues/CogsIssues'
import Cogs from './private/cogs/Cogs'
import Dashboard from './private/dashboard/Dashboard'
import Education from './private/education/Education'
import Integrations from './private/integrations/Integrations'
import Listings from './private/listings/Listings'
import ListingsLocked from './private/listings/ListingsLocked'
import Listing from './private/listings/listing/Listing'
import ListingLocked from './private/listings/listing/ListingLocked'
import Orders from './private/orders/Orders'
import PaymentScreen from './private/payment/PaymentScreen'
import Products from './private/products/Products'
import ProductsLocked from './private/products/ProductsLocked'
import Product from './private/products/product/Product'
import ProductLocked from './private/products/product/ProductLocked'
import Settings from './private/settings/Settings'
import {
  AuthProvider,
  auth0Config,
  useAuth,
  useHandleAuthentication
} from './public/auth/auth-provider'
import { UserProvider, useUser } from './public/auth/user-provider'
import LoadingScreen from './public/loading-screen'
import ProtectedRoute from './public/protected-route'
import SubscribedGuard from './public/subscribed-guard'
import SubscriptionLoadingScreen from './public/subscription-loading-screen'
import UnsubscribedGuard from './public/unsubscribed-guard'
import { AppRoutes } from './shared/app-routes'
import { useNumberFormatter } from './shared/helpers/currency-formatter'
import { CogsCounterProvider } from './shared/providers/cogs-counter.provider'
import { ErrorProvider } from './shared/providers/error.provider'
import { PaddleProvider, usePaddle } from './shared/providers/paddle.provider'
import { PeriodProvider } from './shared/providers/period.provider'
import { ShopProvider } from './shared/providers/shop.provider'
import { useTracking } from './shared/providers/tracking.provider'
import Ads from './private/advertisement/Ads'

const App: Component = () => {
  const [isAuthenticated, setIsAuthenticated] = createSignal(false)
  const [isLoading, setIsLoading] = createSignal(true)

  const { auth } = useAuth()
  const { setCurrentUser, userPurchases, setUserPurchases } = useUser()
  const { paddle, setPaddle } = usePaddle()
  const handleAuthentication = useHandleAuthentication()
  const location = useLocation()
  const { trackUser } = useTracking()
  const { currencyFormatter } = useNumberFormatter()

  onMount(async () => {
    currencyFormatter() // init locale
    void initPaddle()
    handleAuthentication()
      .then(async () => {
        const authState = await auth.isAuthenticated()
        setIsAuthenticated(authState)

        if (!authState) {
          await auth.loginWithRedirect(auth0Config(location.search))
          return
        }

        await initSubscription()
        await initUser()

        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  })

  const initPaddle = async (): Promise<void> => {
    const paddle = await initializePaddle({
      token: import.meta.env.VITE_PADDLE_TOKEN,
      environment: import.meta.env.VITE_PADDLE_ENV,
      checkout: {
        settings: {
          allowLogout: false
        }
      }
    })

    setPaddle(paddle)
  }

  const initUser = async (): Promise<void> => {
    const getCurrentUser = MainModule.getAuthComponent().provideGetCurrentUser()
    const user = await getCurrentUser.execute()

    if (!DEV) {
      void window.profitwell('start', { user_email: user.email })
    }

    paddle()?.Update({
      pwCustomer: {
        id: user.customerId
      }
    })

    setCurrentUser(user)
    trackUser(user, userPurchases())
    Sentry.setUser({ id: user.id })
  }

  const initSubscription = async (): Promise<void> => {
    try {
      const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()
      const purchases = await getPurchases.execute()
      setUserPurchases(purchases)
    } catch (error) {
      console.error(error)
    }
  }

  createEffect(async () => {
    await i18next
      .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: true
        },
        resources: Object.fromEntries(
          Object.entries(translations).map(([lang, translation]) => [
            lang,
            { translation }
          ])
        )
      })
  })

  return (
    <Show when={!isLoading() && isAuthenticated()} fallback={<LoadingScreen />}>
      <TransProvider>
        <AuthProvider>
          <UserProvider>
            <PaddleProvider>
              <Routes>
                <Route path={AppRoutes.LoadingAccount()} component={SubscriptionLoadingScreen}/>
                <Route path={AppRoutes.Payment()} element={<UnsubscribedGuard component={SimpleLayout}/>}>
                  <Route path={AppRoutes.AccountSettings()} component={AccountSettings}/>
                  <Route path="/" component={PaymentScreen}/>
                </Route>
                <Route path={AppRoutes.NoSubscriptionEducation()} element={<SimpleLayout />}>
                  <Route path="/" component={Education}/>
                </Route>
                <Route path="/" element={<SubscribedGuard component={() => <Outlet />}/>}>
                  <ErrorProvider>
                    <PeriodProvider>
                      <ShopProvider>
                        <Route path={AppRoutes.Subscription()} element={<ProtectedRoute component={SimpleLayout}/>}>
                          <Route path="/" component={PaymentScreen}/>
                        </Route>
                        <CogsCounterProvider>
                          <Route path="/" element={<Navigate href={AppRoutes.Dashboard()}/>}/>
                          <Route path="/" element={<ProtectedRoute component={MainLayout}/>}>
                            <Route path={AppRoutes.AccountSettings()} component={AccountSettings}/>
                            <Route path={AppRoutes.Advertisement()} component={Ads}/>
                            <Route path={AppRoutes.Settings()} component={Settings}/>
                            <Route path={AppRoutes.Dashboard()} component={Dashboard}/>
                            <Route path={AppRoutes.Education()} element={<Education/>} />
                            <Route path={AppRoutes.Integrations()} component={Integrations}/>
                            <Route path={AppRoutes.Orders()} component={Orders}/>
                            <Route path={AppRoutes.Catalog()} component={Catalog}/>
                            <Route path={AppRoutes.CogsIssues()} component={CogsIssues}/>
                            <Route path={AppRoutes.ProductCogsPattern()} component={Cogs}/>
                            <Show when={userPurchases()?.isPro}>
                              <Route path={AppRoutes.Products()} component={Products}/>
                              <Route path={AppRoutes.ProductPattern()} component={Product}/>
                              <Route path={AppRoutes.Listings()} component={Listings}/>
                              <Route path={AppRoutes.ListingPattern()} component={Listing}/>
                            </Show>
                            <Show when={!userPurchases()?.isPro}>
                              <Route path={AppRoutes.Products()} component={ProductsLocked}/>
                              <Route path={AppRoutes.ProductPattern()} component={ProductLocked}/>
                              <Route path={AppRoutes.Listings()} component={ListingsLocked}/>
                              <Route path={AppRoutes.ListingPattern()} component={ListingLocked}/>
                            </Show>
                          </Route>
                        </CogsCounterProvider>
                      </ShopProvider>
                    </PeriodProvider>
                  </ErrorProvider>
                </Route>
              </Routes>
            </PaddleProvider>
          </UserProvider>
        </AuthProvider>
      </TransProvider>
    </Show>
  )
}

export default App
