import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'
import { For, Show, type Component } from 'solid-js'
import CheckCircle from '../../assets/heroicons/CheckCircle'
import MiniSparkles from '../../assets/heroicons/MiniSparkles'
import { OfferingType, type OfferingProduct } from '../../features/payment/domain/models/offering'
import { PurchaseProduct } from '../../features/payment/domain/models/one-time-purchase'
import { type OfferingBoxProps } from './PaymentScreen'
import { OfferingPeriod } from './offering-period'

export interface ProOfferingBoxProps extends OfferingBoxProps {
  readonly standalone?: boolean
}

const ProOfferingBox: Component<ProOfferingBoxProps> = (props) => {
  const currencyFormatter = (value?: number, numFractionDigits: number = 2): string => {
    return new Intl
      .NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: numFractionDigits })
      .format(value!)
  }

  const featureListLeft = [
    'ls_pay_pro_feature_l1',
    'ls_pay_pro_feature_l2',
    'ls_pay_pro_feature_l3',
    'ls_pay_pro_feature_l4',
    'ls_pay_pro_feature_l5'
  ]

  const featureListRight = [
    'ls_pay_pro_feature_r1',
    'ls_pay_pro_feature_r2',
    'ls_pay_pro_feature_r3',
    'ls_pay_pro_feature_r4'
  ]

  const getProduct = (): OfferingProduct => {
    return props.period === OfferingPeriod.Month
      ? props.offering.monthly
      : props.offering.annual
  }

  const getActionText = (activeProduct: OfferingProduct | PurchaseProduct): string => {
    return activeProduct.offeringType === OfferingType.Pro ? 'ls_pay_select_plan' : 'ls_pay_upgrade'
  }

  const isCurrentProduct = (): boolean => {
    if (!props.activeProduct || props.activeProduct instanceof PurchaseProduct) return false
    return props.activeProduct.billingInterval.interval === getProduct().billingInterval.interval &&
           props.activeProduct.offeringType === getProduct().offeringType
  }

  return (
    <div class={`flex flex-col w-full max-w-[360px] ${props.standalone ? 'md:max-w-[726px]' : 'lg:max-w-[726px]'} rounded-2xl bg-white overflow-hidden md:shadow-2xl`}>
      {props.standalone}
      <div class="px-8 py-4 flex bg-gray-900 items-center justify-between">
        <div class="flex flex-col justify-center">
          <span class="text-2xl text-white font-semibold"><Trans key="ls_pay_pro"/></span>
        </div>
        <div class="w-fit h-9 flex items-center p-4 bg-[#FEF2CC] text-gray-900 rounded-lg text-sm font-bold whitespace-nowrap">
          <Trans key="ls_pay_best_value"/>
        </div>
      </div>
      <div class="flex flex-col justify-center items-center p-4 gap-2">
        <div class="flex items-baseline gap-1">
          <Show when={props.period === OfferingPeriod.Month}>
            <span class="text-4xl text-gray-900 font-bold">
              {currencyFormatter(props.offering.monthly.price)}
            </span>
            <span>/ <Trans key="ls_shared_month_short"/></span>
          </Show>
          <Show when={props.period === OfferingPeriod.Year}>
            <span class="text-4xl text-gray-900 font-bold">
              {currencyFormatter(props.offering.annual.price / 12)}
            </span>
            <span>/ <Trans key="ls_shared_month_short"/></span>
          </Show>
        </div>
        <div class="flex flex-col text-[#059669] font-semibold text-sm text-center">
          <span><Trans key="ls_pay_early_offer"/></span>
          <span class="flex gap-1 justify-center">
            <span><Trans key="ls_pay_regular_price"/></span>
            {currencyFormatter(props.fakePrices[props.period])}/<Trans key="ls_shared_month_short"/>
          </span>
        </div>
      </div>
      <div class="p-6 flex flex-col gap-4 border-t border-t-gray-200 text-sm">
        <span innerHTML={t('ls_pay_get_pro_access')} />
        <div class="flex justify-between flex-wrap gap-2">
          <div class="flex flex-col gap-2 md:w-[250px]">
            <For each={featureListLeft}>{(feature) => (
              <div class="flex items-center gap-2">
              <span class="text-green-600"><CheckCircle size={4}/></span>
                <span innerHTML={t(feature)} />
              </div>
            )}</For>
          </div>
          <div class="flex flex-col gap-2 md:w-[220px]">
            <For each={featureListRight}>{(feature) => (
              <div class="flex items-center gap-2">
                <span class="text-green-600"><CheckCircle size={4}/></span>
                <span innerHTML={t(feature)} />
              </div>
            )}</For>
          </div>
        </div>
        <div class="flex gap-2 items-center mt-2">
          <span class="text-amber-500"><MiniSparkles/></span>
          <span innerHTML={t('ls_pay_new_coming_soon')} />
        </div>
      </div>
      <div class="flex flex-col p-6 border-t border-t-gray-200 items-center gap-4 text-center">
        <Show when={!props.activeProduct}>
          <button onClick={() => { void props.showCheckout(getProduct().id) }} class="bg-[#FF5E2E] text-white h-14 w-full rounded-full hover:bg-orange-700 transition duration-200 font-medium capitalize">
            <Show fallback={
              <Trans key="ls_pay_select_plan" />
            } when={getProduct().trialPeriod}>{(trial) => (
              <Trans key="ls_pay_select_plan_info" options={{ interval: trial().interval, frequency: trial().frequency }}/>
            )}
            </Show>
          </button>
        </Show>
        <Show when={props.activeProduct}>{(activeProduct) => (
          <>
            <Show when={isCurrentProduct()}>
              <div
                class="bg-gray-400 text-white h-14 w-full flex items-center justify-center rounded-full font-medium">
                <Trans key="ls_pay_current_plan"/>
              </div>
            </Show>
            <Show when={!isCurrentProduct()}>
              <button onClick={() => { void props.showCheckout(getProduct().id) }} class="bg-[#FF5E2E] text-white h-14 w-full flex items-center justify-center rounded-full hover:bg-orange-700 hover:cursor-pointer transition duration-200 font-medium">
                <Trans key={getActionText(activeProduct())}/>
              </button>
            </Show>
          </>
        )}</Show>
      </div>
    </div>
  )
}

export default ProOfferingBox
