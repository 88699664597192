export enum CookieName {
  AuthToken = 'pt_auth_token',
  CurrentShop = 'pt_current_shop',
}

const parseCookies = (): Record<string, string> => {
  return document.cookie.split('; ').reduce<Record<string, string>>((acc, cookie) => {
    const [key, value] = cookie.split('=')
    acc[key] = decodeURIComponent(value)
    return acc
  }, {})
}

export const CookieManager = {
  get(name: CookieName): string | undefined {
    const cookies = parseCookies()
    return cookies[name]
  },
  set(name: CookieName, value: string | number): void {
    const maxAge = 60 * 60 * 24 * 365 * 100
    document.cookie = `${name}=${encodeURIComponent(value.toString())}; Max-Age=${maxAge}; path=/; Secure; SameSite=None`
  },
  delete(name: CookieName): void {
    document.cookie = `${name}=; Max-Age=0; path=/; Secure; SameSite=None`
  },
  deleteAll(): void {
    const cookies = parseCookies()
    for (const name in cookies) {
      this.delete(name as CookieName)
    }
  }
}
