import mixpanel from 'mixpanel-browser'
import { type User } from '../../features/auth/domain/models/user'
import { DEV } from 'solid-js'
import { type Purchases } from '../../features/payment/domain/models/purchases'

export interface TrackingService {
  trackEvent: (event: string, properties?: object) => void
  trackUser: (user: User, purchases?: Purchases) => void
  trackLogout: () => void
}

export class MixpanelService implements TrackingService {
  constructor() {
    if (DEV) { return }

    const token = import.meta.env.VITE_MIXPANEL_TOKEN
    if (token) {
      mixpanel?.init(token, { debug: false, track_pageview: 'url-with-path', persistence: 'localStorage' })
    } else {
      throw new Error('Mixpanel token is missing')
    }
  }

  public trackEvent(event: string, properties?: object): void {
    if (DEV) { return }

    try {
      mixpanel?.track(event, properties)
    } catch (error) {
      console.error('Mixpanel track error:', error)
    }
  }

  public trackUser(user: User, purchases?: Purchases): void {
    if (DEV) { return }

    mixpanel?.identify(user.id)
    mixpanel?.people.set({
      $name: user.name,
      $email: user.email,
      $subscription: purchases?.subscriptionTypes,
      $purchases: purchases?.purchaseTypes
    })
  }

  public trackLogout(): void {
    if (DEV) { return }

    this.trackEvent('Log Out')
    mixpanel?.reset()
  }
}
