import { type Interval } from '../../data/entities/offering.entity'
import { type OneTimePurchaseType } from './one-time-purchase'

export enum OfferingType {
  Base = 'base_sub_000',
  Starter = 'profittree_starter',
  Pro = 'profittree_pro',
  GroupCoaching = 'group_coaching'
}

export enum OfferingProductInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export class OfferingProduct {
  constructor(
    public readonly id: string,
    public readonly price: number,
    public readonly currencyCode: string,
    public readonly offeringType: OfferingType,
    public readonly name: string,
    public readonly billingInterval: Interval,
    public readonly trialPeriod?: Interval
  ) {
  }
}

export class OfferingItem {
  constructor(
    public readonly type: OfferingType | OneTimePurchaseType,
    public readonly products: OfferingProduct[]
  ) {
  }
}

export class Offering {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly type: string,
    public readonly offerings: OfferingItem[]
  ) {
  }
}

export class MainPlansItem {
  constructor(
    public readonly monthly: OfferingProduct,
    public readonly annual: OfferingProduct
  ) {
  }
}

export class MainPlans {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly type: string,
    public readonly starter: MainPlansItem,
    public readonly pro: MainPlansItem
  ) {
  }
}
