import { OfferingType } from './offering'
import { type OneTimePurchase, OneTimePurchaseType } from './one-time-purchase'
import { type Subscription } from './subscription'

export class Purchases {
  constructor (
    public readonly oneTimePurchases: OneTimePurchase[],
    public readonly subscriptions: Subscription[]
  ) {}

  public getGroupCoaching(): Subscription | undefined {
    return this.subscriptions.find(sub => sub.productPrice.offeringType === OfferingType.GroupCoaching)
  }

  public get hasAllInMasterclass(): boolean {
    return this.oneTimePurchases.some(sub => {
      return sub.productPrice.offeringType === OneTimePurchaseType.CourseMasterclass ||
        sub.productPrice.offeringType === OneTimePurchaseType.CourseMasterclassFullPrice
    })
  }

  public get canHaveMultipleShops(): boolean {
    if (this.isPro) {
      return true
    }

    return this.hasUnlimitedShops
  }

  protected get hasUnlimitedShops(): boolean {
    return this.oneTimePurchases.some(sub => sub.productPrice.offeringType === OneTimePurchaseType.UnlimitedShops)
  }

  public get getBaseSubscription(): Subscription | undefined {
    return this.subscriptions.find(sub => sub.productPrice.offeringType === OfferingType.Base)
  }

  public get isLifetime(): boolean {
    return this.oneTimePurchases.some(sub =>
      sub.productPrice.offeringType === OneTimePurchaseType.Lifetime
    )
  }

  public get isPro(): boolean {
    return this.subscriptions.some(sub => sub.productPrice.offeringType === OfferingType.Pro && sub.isValid)
  }

  public get isSubscribedToGroupCoachingSessions(): boolean {
    const sub = this.getGroupCoaching()
    return sub?.isValid ?? false
  }

  public get purchaseTypes(): string[] {
    return this.oneTimePurchases.map(p => p.productPrice.offeringType)
  }

  public get subscriptionTypes(): string[] {
    return this.subscriptions
      .filter(s => s.isValid)
      .map(s => s.productPrice.offeringType)
  }
}
