import { createContext, createSignal, type JSX, useContext } from 'solid-js'
import { type Shop } from '../../features/shop/domain/models/shop'
import { createLocalStore } from '../../private/shared/functions/local-storage.functions'
import * as Sentry from '@sentry/browser'

export class ShopStore {
  constructor(
    public readonly selectedShopId?: number
  ) {
  }
}

interface ShopContextType {
  readonly selectedShopId: () => number | undefined
  readonly selectedShop: () => Shop | undefined
  readonly setSelectedShop: (shop: Shop) => void
  readonly shops: () => Shop[]
  readonly setShops: (shops: Shop[]) => void
}

const [shopState, setShopState] = createLocalStore<ShopStore>('selectedShopId', new ShopStore())
const [shops, setShops] = createSignal<Shop[]>([])
const [selectedShop, setSelectedShop] = createSignal<Shop>()

const updateCurrentShop = (shop: Shop): void => {
  Sentry.setExtra('shop_id', shop.id)
  setShopState(new ShopStore(shop.id))
  setSelectedShop(shop)
}

const defaultContextValue: ShopContextType = {
  selectedShopId: () => shopState.selectedShopId,
  selectedShop,
  setSelectedShop: updateCurrentShop,
  shops,
  setShops
}

const ShopContext = createContext<ShopContextType>(defaultContextValue)

export const ShopProvider = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <ShopContext.Provider value={defaultContextValue}>
      {props.children}
    </ShopContext.Provider>
  )
}

export const useShop = (): ShopContextType => {
  return useContext(ShopContext)
}
